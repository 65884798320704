<template>
<div id="market">
   <div class="market-wrap">
      <div class="search-box">
        <el-input class="searchCt" v-model="searchValue"  placeholder="搜索服务~"></el-input>
        <div class="search-icon" >
          搜索
        </div>
      </div>
      <div class="banner">
         <el-carousel height="260px">
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <img :src="item.picUrl" @click="jumpBanner(item)" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="menuBox">
         <ul>
           <li @click="toggle(1)" :class="{active:type==1}">所有服务</li>
           <li @click="toggle(2)" :class="{active:type==2}">我的订单</li>
         </ul>
         <div class="menuWrap" v-show="type==1">
          <div class="menuList" v-for="(item,index) in serviceList" :key="index">
              <img v-show="index==0" src="@/assets/imgs/market/item-baomu-icon.webp">
              <img v-show="index==1" src="@/assets/imgs/market/item-baojie-icon.webp">
              <img v-show="index==2" src="@/assets/imgs/market/item-banjia-icon.webp">
              <img v-show="index==3" src="@/assets/imgs/market/item-jthuishou-icon.webp">
              <img v-show="index==4" src="@/assets/imgs/market/item-fwwx-icon.webp">
              <img v-show="index==5" src="@/assets/imgs/market/item-shutong-icon.webp">
              <img v-show="index==6" src="@/assets/imgs/market/item-jdwx-icon.webp">
              <img v-show="index==7" src="@/assets/imgs/market/item-peisong-icon.webp">
              <p>
                <label>{{item.label}}</label>
                <span v-for="(child,key) in item.children" :key="key" @click="jumpDetail(child)">
                   {{child.label}}
                 </span>
              </p>
            
          </div>
         </div>
         <div class="orderList"  v-show="type==2">
            <el-tabs v-model="orderStatus" @tab-click="getUserOddJobOrder">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane label="待确定" name="1"></el-tab-pane>
            <el-tab-pane label="待支付" name="2"></el-tab-pane>
            <el-tab-pane label="待服务" name="3"></el-tab-pane>
            <el-tab-pane label="待评价" name="4"></el-tab-pane>
            <el-tab-pane label="退款售后" name="5"></el-tab-pane>
          </el-tabs>
          <div class="orderList">
            <el-table
              :data="orderList"
              style="width: 100%">
              <el-table-column
                prop="placeOrderUserName"
                label="下单人"
                width="100"
               />
              <el-table-column
                prop="serviceGoodsPriceName"
                label="服务名称"
                align="center"
               />
              <el-table-column
                label="订单价格"
                align="center"
              >
                <template slot-scope="scope">
                   ￥{{scope.row.orderPrice}}
                </template>
              </el-table-column>
              <el-table-column
                prop="liaisonNan"
                label="联系人"
                align="center"
               />
              <el-table-column
                prop="arrivalTime"
                label="预约日期"
                align="center"
              />
              <el-table-column
                prop="toDoorTime"
                label="到门时间"
                align="center"
              >
                <template slot-scope="scope">
                   <span v-show="scope.row.toDoorTime==1">8:00-12:00</span>
                   <span v-show="scope.row.toDoorTime==2">12:00-15:00</span>
                   <span v-show="scope.row.toDoorTime==3">15:00-18:00</span>
                </template>
             </el-table-column>
             <el-table-column
                prop="toDoorTime"
                label="订单状态"
                align="center"
              >
                <template slot-scope="scope">
                   <span v-show="scope.row.orderStatus==0">预约</span>
                   <span v-show="scope.row.orderStatus==1">下单</span>
                   <span v-show="scope.row.orderStatus==2">预约确认</span>
                   <span v-show="scope.row.orderStatus==3">待支付</span>
                   <span v-show="scope.row.orderStatus==4">待服务</span>
                   <span v-show="scope.row.orderStatus==5">订单完成</span>
                   <span v-show="scope.row.orderStatus==6">取消订单</span>
                   <span v-show="scope.row.orderStatus==7">退款中</span>
                   <span v-show="scope.row.orderStatus==8">退款成功</span>
                </template>
             </el-table-column>
               <el-table-column
                prop="toDoorTime"
                label="操作"
                width="180"
                align="center"
              >
                <template slot-scope="scope">
                    <span class="operate" v-show="scope.row.orderStatus==1||scope.row.orderStatus==2||scope.row.orderStatus==3" @click="openPay(scope.row)">去支付</span>
                    <span class="operate" v-show="scope.row.orderStatus==0||scope.row.orderStatus==1||scope.row.orderStatus==2||scope.row.orderStatus==3" @click="closeOrder(scope.row)">取消订单</span>
                   <span class="operate" v-show="scope.row.orderStatus==4" @click="makeSure(scope.row)">确认完成</span>
                   <span class="operate" v-show="scope.row.orderStatus==5&&scope.row.commentStatus==0" @click="openRate(scope.row)">待评价</span>
                   <span class="operate" @click="openOrder(scope.row)">详情</span>
                </template>
             </el-table-column>
            </el-table>
          </div>
         </div>
      </div>
     <div class="payDialog">
      <el-dialog
        title="订单支付"
        :visible.sync="payShow"
        width="600px"
       >
        <div class="row-select">
          <el-radio-group v-model="payChannel" @change="changeAmount">
            <el-radio label="1"><div class="row-title"><img src="@/assets/imgs/wxPay.png"><p>微信</p></div></el-radio>
            <el-radio label="2"><div class="row-title"><img src="@/assets/imgs/zhifubao.png"><p>支付宝</p></div></el-radio>
            <el-radio label="3"><div class="row-title"><img src="@/assets/imgs/jianhang.png"><p>建行支付</p></div></el-radio>
          </el-radio-group >
        </div>
        <div class="qrcode">
          <div class="qrcode" id="qrcode" ref="qrcode"></div>
        </div>
        <div>
          <p>支付金额：{{realPrice}}</p>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="payShow = false">取 消</el-button>
          <el-button type="primary" @click="determine">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="payDialog">
      <el-dialog
        title="订单详情"
        :visible.sync="orderShow"
        width="600px"
       >  
       <el-form ref="form" label-width="90px">
        <el-form-item label="服务名称:">
           {{orderInfo.serviceGoodsPriceName}}
        </el-form-item>
        <el-form-item label="下单人:">
           {{orderInfo.placeOrderUserName}}
        </el-form-item>
        <el-form-item label="订单价格:">
           ￥{{orderInfo.orderPrice}}
        </el-form-item>
        <el-form-item label="联系人:">
           {{orderInfo.liaisonNan}}
        </el-form-item>
         <el-form-item label="联系人电话:">
           {{orderInfo.placeOrderPhone}}
        </el-form-item>
        <el-form-item label="地址:">
           {{orderInfo.address}}{{orderInfo.houseNumber}}
        </el-form-item>
         <el-form-item label="预约日期:">
           {{orderInfo.arrivalTime}}
        </el-form-item>
        <el-form-item label="到门时间:">
            <span v-show="orderInfo.toDoorTime==1">8:00-12:00</span>
            <span v-show="orderInfo.toDoorTime==2">12:00-15:00</span>
            <span v-show="orderInfo.toDoorTime==3">15:00-18:00</span>
        </el-form-item>
        <el-form-item label="订单状态:">
            <span v-show="orderInfo.orderStatus==0">预约</span>
            <span v-show="orderInfo.orderStatus==1">下单</span>
            <span v-show="orderInfo.orderStatus==2">预约确认</span>
            <span v-show="orderInfo.orderStatus==3">待支付</span>
            <span v-show="orderInfo.orderStatus==4">待服务</span>
            <span v-show="orderInfo.orderStatus==5">订单完成</span>
            <span v-show="orderInfo.orderStatus==6">取消订单</span>
            <span v-show="orderInfo.orderStatus==7">退款中</span>
            <span v-show="orderInfo.orderStatus==8">退款成功</span>
        </el-form-item>
         <el-form-item label="订单评价:">
            <span v-show="orderInfo.orderStatus==5&&orderInfo.commentStatus==0">待评价</span>
            <span v-show="orderInfo.orderStatus==5&&orderInfo.commentStatus==1">已评价</span>
        </el-form-item>
         <el-form-item label="评价内容:">
            <div class="evaluate"  v-for="(item,index) in orderInfo.orderEvaluateList" :key="index">
               <p>{{item.evaluationContent}}<span>（{{item.evaluateStar}}星）</span></p>
            </div>
        </el-form-item>
       </el-form>
      </el-dialog>
    </div>
      <div class="payDialog">
      <el-dialog
        title="待评价"
        :visible.sync="rateShow"
        width="600px"
       >  
        <el-rate v-model="evaluateStar"></el-rate>
        <el-input type="textarea" v-model="evaluationContent"></el-input>
         <span slot="footer" class="dialog-footer">
          <el-button @click="rateShow = false">取 消</el-button>
          <el-button type="primary" @click="confirmRate">确 定</el-button>
        </span>
      </el-dialog>
    </div>
   </div>
</div>
</template>

<script>
import {getMerchantInfo,getUserOddJobOrderDetails,addOrderEvaluation,confirmPayment,getJoblessSwitch,getOddJobBanner,getServiceTrade,getUserOddJobOrder,confirmCompletionOrder,cancelOrder} from "@/api/index.js";
import QRCode from "qrcodejs2";
export default {
  name: "market",
  data() {
    return {
      searchValue:'',
      bannerList:[],
      serviceList:[],
      type:1,
      orderList:[],
      orderStatus:'0',
      payShow:false,
      orderShow:false,
      orderInfo:{},
      joblessSwitch:true,
      rateShow:false,
      evaluateStar:0,
      evaluationContent:'',
      applicationStatus:'',
      payChannel:'1',
      realPrice:0,
      userInfo: { },
    }
  },
  mounted() {
    this.getServiceTrade()
    this.getOddJobBanner()
    this.getUserOddJobOrder()
    this.getJoblessSwitch()
    this.getMerchantInfo()
    this.type = this.$route.query.type || 1
    this.userInfo =  JSON.parse(localStorage.getItem('userInfo'))
  },
  methods: {
      getMerchantInfo(){
        getMerchantInfo().then(res=>{
          this.applicationStatus = res.data.applicationStatus
        })
      },
      confirmRate(){
        addOrderEvaluation({
          serviceUserOrderId:this.orderInfo.id,
          evaluateStar:this.evaluateStar,
          evaluationContent:this.evaluationContent
        }).then(res=>{
          if(res.code==200){
            this.$message({
                message: '评价成功！',
                type: 'success'
              })
              this.rateShow= false
               this.getUserOddJobOrder()
          }else{
              this.$message({
                message: res.msg,
                type: 'warning'
              })
          }
        })
      },
      openRate(row){
        this.rateShow = true
        this.getUserOddJobOrderDetails(row)
      },
      getUserOddJobOrderDetails(row){
        getUserOddJobOrderDetails({
          id:row.id
        }).then(res=>{
           this.orderInfo = res.data
        })
      },
      /**
       * 确定
       */
      determine(){
        this.payShow = false;
        //刷新
        this.getUserOddJobOrder()
      },
      getJoblessSwitch(){
        getJoblessSwitch().then(res=>{
          this.joblessSwitch = res.data.joblessSwitch
          if( !this.joblessSwitch ){
             this.$router.push({
                path: '/home'
            })
          }
        })
      },
       makeSure(row){
       this.$confirm('确认要完成该订单吗?', '确认完成', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
       }).then(() => {
         confirmCompletionOrder({serviceUserOrderId:row.id}).then(res=>{
          if(res.code==200){
            this.$message({
                  message: '成功',
                  type: 'success'
              })
              this.getUserOddJobOrder()
          }else{
            this.$message({
                  message:res.msg,
                  type: 'warning'
              })
          }
         })
      })
    },
    closeOrder(row){
      this.$confirm('确认要取消该订单吗?', '取消订单', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
       }).then(() => {
         cancelOrder({serviceUserOrderId:row.id}).then(res=>{
          if(res.code==200){
            this.$message({
                  message: '取消订单成功',
                  type: 'success'
              })
              this.getUserOddJobOrder()
          }else{
            this.$message({
                  message:res.msg,
                  type: 'warning'
              })
          }
         })
      })
    },
    changeAmount(){
      var ss = this.orderInfo;
      this.openPay(ss)
    },
    openPay(row){
      this.payShow = true
      this.orderInfo = row;
      /*if(this.$refs.qrCodeUrl){
        this.$refs.qrCodeUrl.innerHTML = ''
      }*/
      if(document.getElementById('qrcode') != null){
        document.getElementById('qrcode').innerHTML = "";
      }
      confirmPayment({
        channel:2,
        orderStatus: row.orderStatus,
        payChannel: this.payChannel,
        serviceUserOrderId: row.id
      }).then(res => {
        this.realPrice = res.data.realPrice;
        /*new QRCode(this.$refs.qrCodeUrl, {
          text: res.data.code_url, //可以是链接，也可以是文本
          width: 132, //二维码的宽度
          height: 132, //二维码的高度
          colorLight: '#ffffff', //二维码背景色
          correctLevel: QRCode.CorrectLevel.H //纠错等级
        })*/
        new QRCode("qrcode", {
          width: 132,
          height: 132,
          colorLight: '#ffffff', //二维码背景色
          correctLevel: QRCode.CorrectLevel.H, //纠错等级
          text: res.data.code_url // 生成二维码的链接
        });
      })
    },
    openOrder(row){
      this.getUserOddJobOrderDetails(row)
      this.orderShow = true
    },
   
    getUserOddJobOrder(){
      getUserOddJobOrder({
        orderStatus:this.orderStatus
      }).then(res=>{
        this.orderList = res.rows
      })
    },
    toggle(index){
      this.type = index
    },
    jumpDetail(param){
       this.$router.push({
          path: '/service',
          query:{
            serviceTrade:param.value
          }
        })
    },
    getServiceTrade(){
      getServiceTrade().then(res=>{
        this.serviceList = res.rows
      })
    },
    jumpBanner(item){
        if(this.userInfo.type==3){
          this.$router.push({
            path: '/login'
          })
            this.$message({
                message:'请登陆',
                type: 'warning'
            })
        }else{
         if(this.applicationStatus=='0'){
            this.$router.push({
              path: '/merchant'
            })
          }else{
            this.$router.push({
              path: '/applyInfo?introduceUrl='+item.introduceUrl+'&settledType='+item.settledType,
            })
          }
        }
    },
    getOddJobBanner(){
      getOddJobBanner({
        type:1
      }).then(res=>{
        this.bannerList = res.rows
      })
    },
  }
};
</script>

<style lang="scss" scoped>
#market {
  width: 100%;
  min-height: 200px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-top: 24px;
}

.market-wrap {
  width: 1200px;
  min-height: 200px;
  margin: 0 auto;
}
.banner{
  img{
    width: 100%;
    height:260px;
    cursor: pointer;
    border-radius: 10px;
  }
}
.menuBox{
  width: 1160px;
  min-height: 523px;
  background: #FFFFFF;
  border-radius: 4px;
  margin: 20px 0;
  padding: 20px;
  .orderList{
    margin-top: 15px;
  }
  ul{
    height: 40px;
    overflow: hidden;
    li{
      float: left;
      font-size: 20px;
      width:80px;
      text-align: left;
      color: #222;
      padding-bottom: 8px;
      cursor: pointer;
      margin-right: 20px;
    }
    .active{
       border-bottom: 2px solid #0077FF;
       cursor: pointer;
       color: #0077FF;
    }
  }
  h3{
    font-size: 20px;
    height: 40px;
    color: #222;
    border-bottom: 1px solid #eee;
  }
}
.menuList{
  width: 580px;
  float: left;
  overflow: hidden;
  margin-top: 35px;
  img{
    width: 100px;
    height:70px;
    float: left;
    margin-right: 15px;
  }
  p{
    width:420px;
    float: left;
    label{
      display: block;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 6px;
    }
    span{
      font-size: 14px;
      line-height:25px;
      color: #0077FF;
      margin-right: 14px;
      cursor: pointer;
    }
    span:hover{
      color: #65a8f4;
    }
  }
}
.operate{
  color: #0077FF;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}
.walletDialog{
  /deep/.el-radio {
    display:inline-flex !important;
    align-items: center !important;
    .el-radio__input {
      display:inline-flex !important;
      align-items: center !important;
    }
  }

  .moneyList{
    overflow: hidden;
    margin-left: 25px;
  }
  .row-title{
    margin-top:24px;
    margin-bottom: 24px;
    overflow: hidden;
    img{
      float: left;
      width: 18px;
      height: 18px;
    }
    span{
      display: block;
      float: left;
      font-size: 14px;
      font-weight: 500;
      color: #414a60;
      margin-left: 8px;
    }
  }

  .money-item {
    width: 125px;
    border: 1px solid #e3e7ed;
    float: left;
    margin-right: 25px;
    border-radius:6px;
    margin-bottom: 20px;
    text-align: center;
    padding:13px;
    color: #414a60;
    font-size: 14px;
    cursor: pointer;
    label{
      display: block;
      margin-bottom: 3px;
      font-size: 16px;
    }
  }
  .activeWallet{
    border: 1px solid #FF2400;
    background: rgb(250, 229, 229);
    color: #FF2400;
  }
  .qrcode{
    width: 132px;
    margin: 20px auto;
  }
}
.row-select{
  clear: both;
  padding-left: 25px;
}
.payDialog{

 .row-title{
      margin-top:6px;
      margin-bottom: 10px;
      overflow: hidden;
      img{
        float: left;
        width: 18px;
        height: 18px;
      }
      span{
        display: block;
        float: left;
            font-size: 14px;
    font-weight: 500;
    color: #414a60;
    margin-left: 8px;
      }
    }
.qrcode{
      text-align: center;
   img{
    width: 160px;
    margin: 20px auto;
  }
}
}
.evaluate{
  span{
    color: rgb(255, 118, 6);
  }
}
</style>
<style lang="scss">

.payDialog{
   .el-dialog__body{
    padding:10px 30px;
  }
  .el-form-item__label{
    text-align: left;
  }
  .el-form-item{
    margin-bottom: 4px;
  }
  .el-dialog__footer{
    text-align: center;
  }
  .el-textarea{
    margin-top:20px;
  }
  .el-textarea__inner{
    height: 200px;
  }
}
</style>

